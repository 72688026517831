import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/authService/auth.service';
//import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private authService: AuthService) { }
  footerData = {}; 

  show_terms = 'none';
  terms = '';

  show_imprint = 'none';
  imprint = ''; 
  
  show_data_protection = 'none';
  data_protection = ''; 

  footerText = 'text is here';
  modalShown = false; 

  ngOnInit() {
    this.footerData = this.authService.getFooterInfo().subscribe(data=>{
      console.log('got data: ', data);

      if (data['show_terms'] == 'link'){
        if (data['terms_link'] && data['terms_link'].length > 0){
          this.show_terms = 'link';
          this.terms = data['terms_link'];
        }
      } else if (data['show_terms'] == 'popup'){
        if (data['terms_text'] && data['terms_text'].length > 0){
          this.show_terms = 'popup';
          this.terms = data['terms_text'];
        }
      }

      if (data['show_imprint'] == 'link'){
        if (data['imprint_link'] && data['imprint_link'].length > 0){
          this.show_imprint = 'link';
          this.imprint = data['imprint_link'];
        }
      } else if (data['show_imprint'] == 'popup'){
        if (data['imprint_text'] && data['imprint_text'].length > 0){
          this.show_imprint = 'popup';
          this.imprint = data['imprint_text'];
        }
      }

      if (data['show_data_protection'] == 'link'){
        if (data['data_protection_link'] && data['data_protection_link'].length > 0){
          this.show_data_protection = 'link';
          this.data_protection = data['data_protection_link'];
        }
      } else if (data['show_data_protection'] == 'popup'){
        if (data['data_protection_text'] && data['data_protection_text'].length > 0){
          this.show_data_protection = 'popup';
          this.data_protection = data['data_protection_text'];
        }
      }
    });
  }


  termsClicked(){
    console.log('termsClicked');
    if (this.show_terms == 'link') window.open(this.terms, "_blank");
    else {
      this.modalShown = true; 
      this.footerText = this.terms; 
    }
  }
  dpClicked(){
    console.log('dpClicked');
    if (this.show_data_protection == 'link') window.open(this.data_protection, "_blank");
    else {
      this.modalShown = true; 
      this.footerText = this.data_protection; 
    }

  }
  imprintClicked(){
    console.log('imprintClicked');
    if (this.show_imprint == 'link') window.open(this.imprint, "_blank");
    else {
      this.modalShown = true; 
      this.footerText = this.imprint; 
    }

  }

  closeModal() {
    this.modalShown = false; 
    this.footerText = ''; 
  }
}
