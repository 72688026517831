import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private route: ActivatedRoute, private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const appUser: any = JSON.parse(localStorage.getItem('appUser'));

    if (appUser && appUser.token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + appUser.token)
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          if (this.router.url !== '/login') {
            this.router.navigateByUrl('/login');
          }
        }

        const error = err.error.message || err.error.messages || err.statusText;
        return throwError(error);
      })
    );
  }
}
