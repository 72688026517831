import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  getInfo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const options = {
      httpOptions,
      params: {}
    };
    return this.http.get(
      `${environment.apiUrl}/api2/clientarea/getinfo`,
      options
    );
  }
  updatePassword(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const options = {
      httpOptions,
      params: {}
    };
    return this.http.post(
      `${environment.apiUrl}/api2/clientarea/changePassword`,
      data,
      options
    );
  }

  update(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const options = {
      httpOptions,
      params: {}
    };
    return this.http.post(
      `${environment.apiUrl}/api2/clientarea/setinfo`,
      data,
      options
    );
  }
}
