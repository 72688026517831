import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('appUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Observable<any> {
    return this.currentUserSubject;
  }

  login(login: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const options = {
      httpOptions,
      params: {}
    };
    const client = '1';
    return this.http
      .post(`${environment.apiUrl}/login`, { login, password, client }, options)
      .pipe(
        map((data: any) => {
          if (data.token) {
            const appUser = { token: data.token, login };
            this.currentUserSubject.next(appUser);
            localStorage.setItem('appUser', JSON.stringify(appUser));
            this.router.navigateByUrl('/client');

            return true;
          } else {
            return data;
          }
        })
      );
  }

  logout() {
    localStorage.removeItem('appUser');
    const user = null;
    this.currentUserSubject.next(user);
    this.router.navigateByUrl('/login');

    return of(true);
  }

  getFooterInfo(){
    return this.http.get(`${environment.apiUrl}/footerData`)
  }
}
