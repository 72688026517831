// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const hostname = window && window.location && window.location.hostname;
let server = 'http://crm.ceragem-ludwigshafen.de/';

if (hostname == 'client.s1.ceragem-crm.de'){
  server = 'http://crm.s1.ceragem-crm.de/'
}

if (hostname == 'client.s2.ceragem-crm.de'){
  server = 'http://crm.s2.ceragem-crm.de/'
}

if (hostname == 'client.s3.ceragem-crm.de'){
  server = 'http://crm.s3.ceragem-crm.de/'
}
let host = hostname.split('.')[1];

//host = 's1';
//server = 'http://crm.s1.ceragem-crm.de/'
export const environment = {
  production: false,
  host: host, 
  //apiUrl: 'http://crm.s1.ceragem-crm.de/slimapi/public'
  apiUrl: `${server}slimapi/public`, 
  restoreUrl: `${server}password-reset/`
};
// export const environment = {
//   production: true,
//   //apiUrl: 'http://crm.ceragem-ludwigshafen.de/slimapi/public'
//   apiUrl: 'http://crm.s1.ceragem-crm.de/slimapi/public'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
